:root {
  --primaryColor: #fdaf17;
}
.achieveBox {
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 27px;
}
.aboutPage .hasselHeader {
  margin-bottom: 13px !important;
  text-align: center;
}
.achiveSubHeader {
  font-size: 16px !important;
  color: #ffffffda;
  font-family: "Satoshi" !important;
  text-align: center;
  width: 100%;
}
.achieverListBox {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: start;
  gap: 20px;
  overflow: scroll;
}
.achieverListBox::-webkit-scrollbar {
  display: none;
}
.achiverCard {
  width: 25%;
  min-width: 250px;
  max-width: 285px;
  height: 350px;
  border-radius: 5px;
  background: #292726;
  box-shadow: 0px 4px 44px 0px #0000001f;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  gap: 15px;
  position: relative;
}
.achiverCard .imgBox {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
}
.achiverCard .imgBox img {
  width: 100%;
  height: 100%;
}
.achiverCard .achiName {
  font-size: 20px !important;
  font-family: "PoppinsBold" !important;
  color: #fff;
}
.achiverCard .achivCardSummery {
  font-size: 16px !important;
  color: #fff;
  font-family: "Satoshi" !important;
  text-align: center;
}
.achivIconBox {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.achiverCard .appBtn {
  position: absolute;
  bottom: -17px;
}
.roadMap {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fff9e6;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 60px;
}
.roadMapText {
  font-size: 30px !important;
  color: #000;
  font-family: "PoppinsBold" !important;
  margin-bottom: 30px !important;
}
.roadMapbox {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  overflow: scroll;
}
.roadMapbox::-webkit-scrollbar {
  display: none;
}
.lineMainBox {
  width: 100%;
  border: 1px solid red;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 252px;
}
.YellowBox {
  width: 25%;
  min-width: 222px;
  height: 200px;
  background: var(--primaryColor);
  padding: 20px;
  border-radius: 8px;
  position: relative;
}
.YellowBox p {
  font-size: 20px !important;
  color: #000;
  font-family: "PoppinsBold" !important;
  margin-bottom: 20px;
}
.YellowBox sapn {
  font-size: 14px !important;
  color: #000;
  font-family: "Satoshi" !important;
}
.lineBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.blackDotBox {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blackDot {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #000000;
}
.line {
  width: 6%;
  min-width: 40px;
  height: 2px;
  border: 1px solid #000;
}
.blackDotright {
  position: absolute;
  right: -12px;
  top: 88px;
}
.blackDotLeft {
  position: absolute;
  left: -12px;
  top: 88px;
}
.ourMvSection {
  width: 100%;
  height: 570px;
  display: flex;
  justify-content: start;
  align-items: center;
  position: relative;
  background: #211d1c;
}
.ourMvBackground {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  left: 0px;
}
.ourtMvImageBox {
  width: 100%;
  height: 100%;
  z-index: 1;
}
.ourtMvImageBox img {
  width: 100%;
  height: 100%;
}
.ourMvTextBox {
  width: 100%;
  background: #fdaf17d9;
  border-radius: 1px;
  position: absolute;
  right: 0px;
  z-index: 1;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.ourMvTextBox img {
  width: 27px;
}
.ourHeaderText {
  font-family: "PoppinsBold" !important;
  font-size: 20px !important;
  color: #000000;
}
.oursubText {
  font-family: "Satoshi" !important;
  font-size: 14px !important;
  color: #000;
}
.stuHoSection {
  width: 100%;
  position: relative;
  background: #211d1c;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
}
.stuHoTextbox {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  position: relative;
}
.stuhoInnerImg {
  width: 100%;
  height: 70%;
  position: absolute;
  right: 0px;
}
.stuhoInnerText {
  width: 100%;
  z-index: 2;
}
.stuhoInnerImg img {
  width: 100%;
  height: 100%;
}
.stuhoHeaderText {
  font-family: "PoppinsBold" !important;
  font-size: 30px !important;
  color: #fff;
  margin-bottom: 15px !important;
}
.stuhoSubText {
  font-family: "Satoshi" !important;
  font-size: 14px !important;
  color: #ffffffda;
  margin-bottom: 20px !important;
}
.stuhoSubText span {
  font-weight: 600;
  font-size: 16px;
  font-style: italic;
}
.stuHoTextbox .countBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}
.countInnerBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.countInnerBox span {
  font-family: "Satoshi" !important;
  font-size: 13px !important;
  color: #fffde6d8;
}
.countInnerBox p {
  font-family: "PoppinsBold" !important;
  font-size: 25px !important;
  color: #fff;
}
.countInnerBox img {
  width: 20px;
}
.omtbHeader {
  font-size: 28px !important;
  font-family: "PoppinsBold" !important;
  font-weight: 600 !important;
}
/* Tabe view */
@media only screen and (min-width: 768px) {
  .stuHoTextbox {
    width: 90%;
    height: 400px;
  }
  .countInnerBox p {
    font-size: 20px !important;
  }
  .oursubText {
    font-size: 12px !important;
  }
  .achieveBox {
    width: 90%;
  }
  .countInnerBox img {
    width: 30px;
  }
  .stuhoInnerImg {
    width: 65%;
    height: 70%;
  }
  .stuhoInnerText {
    width: 50%;
  }
  .stuHoTextbox .countBox {
    margin-top: 0px;
  }
  .ourtMvImageBox {
    width: 60%;
  }
  .ourMvTextBox {
    width: 50%;
    height: 100%;
    height: auto;
    background: var(--primaryColor);
    padding: 30px;
  }
  .ourMvTextBox img {
    width: 44px;
  }
  .ourHeaderText {
    font-size: 25px !important;
  }
  .aboutPage .hasselHeader {
    text-align: start;
  }
  .achiveSubHeader {
    width: 80%;
  }
  .stuHoSection {
    height: 550px;
    padding: 0;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .stuHoTextbox {
    width: 70%;
  }
  .countInnerBox p {
    font-size: 38px !important;
  }
  .countInnerBox span {
    font-size: 16px !important;
  }
  .roadMapbox {
    width: 95%;
    overflow: unset;
  }
  .oursubText {
    font-size: 14px !important;
  }
  .achieveBox {
    width: 75%;
    margin-bottom: 75px;
  }
  .achieverListBox {
    overflow: unset;
    height: unset;
    justify-content: center;
  }
  .roadMap {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .countInnerBox img {
    width: 37px;
  }
  .YellowBox sapn {
    font-size: 15px !important;
  }
}
