:root {
  --primaryColor: #fdaf17;
}
.homeContainer {
  width: 100%;
}
.homeSection {
  width: 100%;
  height: 100vh;
  position: relative;
}
.homeSection .HomeBG {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  z-index: -2;
}
.homeCenterBox {
  width: 100%;
  height: 66%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 11%;
}
.homeLabel {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.homeLabel img {
  position: absolute;
  top: -6px;
  right: 40px;
  width: 51px;
  display: none;
}
.homeBoldText {
  font-size: 35px !important;
  font-family: "PoppinsBold" !important;
  font-weight: 700 !important;
  text-align: center;
  color: #fffae6;
  text-shadow: 0px 3px 3px #4e4e4e;
  margin-bottom: 5px !important;
}
.HCNText {
  font-size: 20px !important;
  width: 95%;
  font-family: "Satoshi" !important;
  text-align: center;
  color: #fffae6;
}
.HCNText span {
  color: var(--primaryColor);
}
.mainSearchBox {
  width: 99%;
  height: 100px;
  background: #221e1d;
  border-radius: 20px;
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px solid gray;
}

.innerSearchBox {
  width: 95%;
  height: 62%;
  border-radius: 40px;
  background: #393534;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  padding: 0 20px;
}
.locationSechBox {
  width: 31%;
  border-right: 1px solid rgba(128, 128, 128, 0.158);
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  gap: 10px;
}
.sechInputBox {
  /* width: 70%; */
  height: 100%;
  position: relative;
}
.sechInputBox input {
  width: 100%;
  height: 100%;
  border-radius: 0px 20px 20px 0;
  border: none;
  background: rgba(255, 0, 0, 0);
  color: #939393;
  padding-left: 25px;
  font-size: 16px;
}
.sechInputBox input:focus {
  outline: none;
}
.locDropText {
  font-size: 14px !important;
  font-family: "Satoshi" !important;
  color: #939393;
}
.dropIcon {
  position: absolute;
  top: 30px;
  right: 10px;
  transition: all 0.5s;
}
.searchBtn {
  width: 94px;
  height: 38px;
  background: var(--primaryColor);
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s;
}

.searchBtnDecibel {
  background: gray;
  pointer-events: none;
}
.mainSearchBox .locationIcon {
  display: none;
}
.searchBtn:hover {
  background: #fdb017ce;
}
.searchBtn p {
  font-family: "Satoshi" !important;
  color: #fff;
}
.selectorBox {
  width: 20%;
  height: 37px;
  position: absolute;
  top: -37px;
  left: 0px;
  border-radius: 20px 20px 0 0;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}
.PropertyList {
  top: 101px !important;
}
.PropertyList .dropItem {
  flex-direction: row;
  gap: 16px;
  height: auto;
  padding: 10px;
}
.PDImgBox {
  width: 38%;
}
.PDImgBox img {
  width: 100%;
}
.PDTitleBox {
  display: flex;
  flex-direction: column;
}
.PDTitleBox p,
.PDTitleBox span {
  font-size: 17px;
  font-family: "Poppins";
  color: #fff;
}
.PDTitleBox span {
  font-size: 13px;
  color: #ffffffb9;
  margin-top: 5px;
}
.sltIBox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff8eb;
  cursor: pointer;
}
.sltBoxActive {
  background: var(--primaryColor);
}
.sltIBox p {
  font-family: "Poppins" !important;
  color: #393534;
  font-size: 13px !important;
  font-weight: 600 !important;
}
.dropBox {
  position: absolute;
  width: 100%;
  border-radius: 16px;
  overflow: scroll;
  top: 66px;
  left: 0px;
  transition: all 0.5s;
  overflow-x: hidden;
}
.dropItem {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  background: #393534;
  border-bottom: 1px solid #0000004b;
  transition: all 0.5s;
  cursor: pointer;
}
.dropItem:hover {
  background: #4d4745;
}
.dropItem p {
  color: #fff;
}
.hasselBox {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #232122;
  padding-top: 30px;
}
.hessleInputBox {
  width: 95%;
  height: 80px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
}
.hasselHeader {
  font-family: "PoppinsBold" !important;
  font-size: 28px !important;
  font-weight: 700 !important;
  text-align: start;
  margin: 20px 0 !important;
  color: #fff;
}
.hasselInnerBox {
  width: 50%;
  height: 100%;
  border-radius: 7px;
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.hasselInnerBox .hasselIcon {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 85px !important;
}
.hessleInputBox .label {
  font-size: 12px !important;
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  color: #ffffffd8;
  position: relative;
  z-index: 1;
}
.hessleInputBox .subSabel {
  display: none;
  font-family: "Satoshi" !important;
  color: #ffffffd7;
  position: relative;
  z-index: 1;
}
.hasselInnerBox img {
  width: 23px;
}
.PropertiesSection {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #232122;
  padding: 25px 0;
  padding-bottom: 100px;
}
.proertiseBox {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 20px;
  overflow: scroll;
}
.proertiseBox::-webkit-scrollbar {
  display: none;
}
.propertiesCard {
  width: 33%;
  min-width: 300px;
  border-radius: 10px;
  background: #2e2b2b;
  padding: 15px;
}
.coverImg {
  width: 100%;
  height: 170px;
  border-radius: 10px;
  position: relative;
}
.coverImg img {
  width: 100%;
  height: 100%;
}
.propCardTextBox {
  /* border: 1px solid red; */
  margin-top: 20px;
}
.startBox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.cardBtn {
  width: 87px;
  height: 27px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primaryColor);
  cursor: pointer;
}
.cardBtn p {
  font-family: "Satoshi" !important;
  font-size: 14px !important;
  font-weight: 500;
  color: #ffffffd8;
}
.starInBox {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}
.starInBox p {
  font-size: 15px !important;
  color: #ffffffd8;
}
.starInBox p span {
  font-size: 15px !important;
  color: #9e9e9e;
}
.proCardTitle {
  font-size: 14px !important;
  font-weight: 700 !important;
  font-family: "Satoshi" !important;
  color: #ffffffd8;
  cursor: pointer;
}
.CardlocationBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px 0;
  gap: 10px;
}
.CardlocationBox p {
  font-size: 11px !important;
  font-family: "Satoshi" !important;
  color: #ffffffd8;
}
.proDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
}
.proDetails img {
  width: 12px;
  height: 12px;
}
.proDetails p {
  font-size: 9px !important;
  color: #9e9e9e;
  font-family: "Satoshi" !important;
}
.priceBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
.priceBox span {
  font-size: 18px !important;
  font-family: "Satoshi" !important;
  font-weight: 700 !important;
  color: #858585;
}
.priceBox p {
  font-size: 18px !important;
  font-family: "Satoshi" !important;
  font-weight: 700 !important;
  color: #fff;
}
.priceBox p samp {
  font-size: 18px !important;
  font-family: "Satoshi" !important;
  font-weight: 700 !important;
  color: var(--primaryColor);
}
.propertiesCard .loveIcon {
  position: absolute;
  right: 11px;
  bottom: 11px;
  width: 35px;
  height: 35px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.propertiesCard .loveIcon img {
  width: 14px;
  height: 13px;
}
.btnBox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.workSection {
  width: 100%;
  position: relative;
  background: var(--primaryColor);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;
}
.workSection .hasselHeader {
  margin: 20px 0 !important;
  margin-left: 35px !important;
}
.workSection .HCNText {
  width: 95%;
}
.workSection .propertiesCard {
  display: flex;
  height: 396px;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  align-items: center;
  background: #fff;
}
.workCardTitle {
  font-size: 15px !important;
  font-weight: 700 !important;
  color: #000;
  font-family: "Poppins" !important;
  text-align: center;
}
.workCardSubTitle {
  font-size: 16px !important;
  font-family: "Satoshi" !important;
  color: #000;
  text-align: center;
}
.workSection .proertiseBox {
  margin-top: 50px;
}
.workSection .paperTop {
  width: 100%;
  position: absolute;
  top: -2px;
}
.workSection .paperBottom {
  width: 100%;
  position: absolute;
  bottom: -1px;
}
.apartmentBox {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../Assets/Images/blackBg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0;
}
.apartmentInnerBox {
  width: 95%;
  /* height: 283px; */
  background: var(--primaryColor);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.aptHeaderText {
  font-size: 24px !important;
  text-align: start;
  font-family: "PoppinsBold" !important;
  font-weight: 700 !important;
  color: #000;
}
.aptSubHeaderText {
  font-size: 15px !important;
  color: #00000098;
  font-family: "Satoshi" !important;
  margin: 15px 0 !important;
}
.apartmentInnerBox .apartMentImg {
  width: 38%;
  position: absolute;
  right: 0px;
  bottom: 0px;
}
.aparEmailBox {
  width: 100%;
  background: var(--primaryColor);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 47px 0;
}
.aparEmailBox .paperTop {
  position: absolute;
  top: -2px;
  width: 100%;
}
.aparMailBox {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
}
.mailSendBox {
  width: 30%;
  min-width: 318px;
  height: 60px;
  border-radius: 10px;
  background: #ffff;
  position: relative;
}
.sendMailBtn {
  position: absolute;
  top: 7px;
  right: 7px;
  width: 45px;
  height: 45px;
  background: var(--primaryColor);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.aparEmailBox .aptHeaderText {
  font-size: 24px !important;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
}
.mailSendBox input {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 10px;
  padding-left: 40px;
}
.mailSendBox .mailIcon {
  position: absolute;
  top: 23px;
  left: 10px;
}
.aparEmailBox .InboxIcon {
  display: none;
}
.aptSubHeader_Text {
  text-align: center;
}
.HomeBGDesktop {
  display: none;
}
.HomeBGTabe {
  display: none;
}
.mobileSearchBar {
  width: 85%;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 33px;
  height: 54px;
  margin-top: 30px;
  position: relative;
}
.mobileSearchBar input {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 33px;
  background: #0000000a;
  padding-left: 72px;
  color: #fff;
  font-family: "Poppins" !important;
}
.mobileSearchBar input:focus {
  outline: none;
}
.mobileSearchBar .searchIocn {
  position: absolute;
  top: 8px;
  left: 12px;
  width: 35px;
}
.mobileSearchBar .whiteLine {
  position: absolute;
  top: 12px;
  left: 58px;
  width: 2px;
  height: 30px;
  opacity: 0.8;
}
.mobileSearchBar .filterIcon {
  position: absolute;
  top: 14px;
  right: 9px;
}
.mobileSearchBar p {
  position: absolute;
  color: #fff;
  top: 15px;
  right: 37px;
  font-size: 14px;
}
.MDropBox {
  position: absolute;
  width: auto;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  top: 58px;
  right: 20px;
  border-radius: 15px;
  overflow-x: hidden;
  transition: all 0.5s;
}
.MDropBox .dropItem {
  width: 100%;
  min-height: 30px;
  background: #ffffffcb;
  padding: 0 20px;
}
.workCardBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  gap: 10px;
  margin-top: 20px;
}

.workCardBox .propertiesCard {
  width: 31%;
  height: 100px;
  min-width: unset;
  padding: 8px;
  justify-content: center;
  gap: 3px;
}
.workCardBox .propertiesCard img {
  width: 25px;
}
.workCardBox .propertiesCard .workCardSubTitle {
  display: none;
}
.workCardBox .propertiesCard .appBtn {
  display: none;
}
.apartmentBox .appBtn {
  width: 150px;
  min-height: 37px;
}
.loveIcon {
  width: 16px;
  height: 16px;
}
.userInfoInputBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 99% !important;
  height: 60vh !important;
  height: 400px;
  border-radius: 10px;
  border: gray 1px solid;
  padding: 20px;
  background: #181717;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.disImgBox {
  width: 100% !important;
  height: 100% !important;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.disImgBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.disTextImBox {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.disText1 {
  font-size: 30px;
  color: #fff;
  font-family: "PoppinsBold";
  text-align: center;
}
.disText2 {
  font-size: 80px;
  color: #fff;
  font-family: "Satoshi";
  text-align: center;
}
.disInputBox {
  width: 100% !important;
  height: 100% !important;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6%;
  padding-top: 20px;
}
.disInputBox .inputBox {
  height: 58px;
}
.disInputBox .fromBtnBox {
  margin-top: 3%;
}
/* Tabe view */
@media only screen and (min-width: 768px) {
  .HCNText {
    width: 90%;
    font-size: 17px !important;
  }
  .proCardTitle {
    font-size: 14px !important;
  }

  .priceBox span {
    font-size: 16px !important;
  }
  .priceBox p {
    font-size: 16px !important;
  }
  .priceBox p samp {
    font-size: 16px !important;
  }
  .workSection .HCNText {
    width: 80%;
  }

  .HomeBGMobile {
    display: none;
  }
  .HomeBGTabe {
    display: block;
  }
  .workSection .propertiesCard {
    width: 33%;
    min-width: 300px;
    border-radius: 10px;
    padding: 15px;
  }
  .homeBoldText {
    font-size: 50px !important;
  }

  .mobileSearchBar {
    display: none;
  }
  .homeLabel {
    width: 49%;
    max-width: 671px;
    min-width: 671px;
  }
  .hasselHeader {
    font-size: 40px !important;
    font-weight: 700 !important;
    text-align: center;
    margin: 40px 0 !important;
  }
  .hasselInnerBox {
    padding: 30px 20px;
    border-radius: 19px;
    display: block;
  }
  .hasselInnerBox img {
    width: 40px;
  }
  .hessleInputBox {
    width: 90%;
    height: 226px;
    gap: 20px;
  }
  .hasselInnerBox .hasselIcon {
    width: 212px !important;
  }
  .hessleInputBox .label {
    font-size: 20px !important;
    margin: 10px 0 !important;
  }
  .PropertiesSection {
    padding: 60px 0;
  }
  .workSection {
    padding: 100px 0;
  }
  .workSection .hasselHeader {
    margin-left: 0 !important;
  }
  .workCardTitle {
    font-size: 24px !important;
    text-align: start;
  }
  .apartmentBox {
    padding: 50px 0;
  }
  .apartmentInnerBox {
    width: 95%;
    /* height: 283px; */
    background: var(--primaryColor);
    border-radius: 30px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .aptHeaderText {
    width: 80%;
    font-size: 40px !important;
  }
  .aptSubHeaderText {
    width: 52%;
  }
  .apartmentBox .appBtn {
    width: 207px;
    min-height: 50px;
  }
  .apartmentInnerBox .apartMentImg {
    width: 52%;
  }
  .aparEmailBox .aptHeaderText {
    font-size: 30px !important;
  }
  .workCardBox {
    overflow: scroll;
    gap: 20px;
  }
  .workCardBox .propertiesCard {
    height: 396px;
    gap: 16px;
  }
  .workCardBox .propertiesCard img {
    width: unset;
  }
  .workCardBox .propertiesCard .workCardSubTitle {
    display: block;
  }
  .workCardBox .propertiesCard .appBtn {
    display: flex;
  }
  .workCardBox::-webkit-scrollbar {
    display: none;
  }
  .hessleInputBox .subSabel {
    font-size: 13px !important;
    display: block;
  }
  .homeLabel img {
    top: -12px;
    right: -7px;
    width: 100px;
    display: block;
  }
  .CardlocationBox p {
    font-size: 13px !important;
  }
  .proDetails p {
    font-size: 11px !important;
  }
  .proertiseBox {
    justify-content: center;
  }
  .searchBtn {
    width: 154px;
    height: 47px;
  }
  .mainSearchBox .locationIcon {
    display: block;
  }
  .locationSechBox {
    width: 25%;
  }
  .PDImgBox {
    width: 16%;
  }
  .PDTitleBox p,
  .PDTitleBox span {
    font-size: 22px;
  }
  .PDTitleBox span {
    font-size: 16px;
  }
  .userInfoInputBox {
    flex-direction: row;
    width: 99% !important;
    height: 50vh !important;
  }
  .disInputBox {
    width: 60% !important;
    padding: 20px;
    gap: 13%;
  }
  .disImgBox {
    width: 40% !important;
  }
  .disTextImBox {
    height: 100%;
  }
  .disInputBox .fromBtnBox {
    margin-top: 10%;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .homeCenterBox {
    gap: 0;
  }
  .HCNText {
    width: 55%;
  }
  .mainSearchBox {
    width: 55%;
  }
  .hessleInputBox {
    width: 65%;
  }

  .proertiseBox {
    width: 78%;
    min-width: unset;
    display: flex;
    flex-direction: row;
    gap: 20px;
    overflow: unset;
  }

  .proCardTitle {
    font-size: 18px !important;
  }
  .proDetails img {
    width: 20px;
    height: 20px;
  }
  .proDetails p {
    font-size: 14px !important;
  }
  .priceBox span {
    font-size: 18px !important;
  }
  .priceBox p {
    font-size: 18px !important;
  }
  .priceBox p samp {
    font-size: 18px !important;
  }
  .workSection .HCNText {
    width: 45%;
  }
  .apartmentInnerBox {
    width: 70%;
  }
  .aparEmailBox .InboxIcon {
    display: block;
  }
  .aparMailBox {
    width: 70%;
    flex-direction: row;
  }
  .aptHeaderText {
    width: 65%;
  }
  .aptSubHeader_Text {
    text-align: start;
  }
  .HomeBGDesktop {
    display: block;
  }

  .HomeBGTabe {
    display: none;
  }
  .workSection .propertiesCard {
    min-width: unset;
  }
  .homeLabel img {
    top: -14px;
    right: -7px;
    width: 104px;
  }
  .CardlocationBox p {
    font-size: 16px !important;
  }
  .proDetails p {
    font-size: 14px !important;
  }
  .apartmentBox {
    padding: 150px 0;
  }
  .aparEmailBox {
    height: 300px;
    padding: 0px;
  }
  .hasselHeader {
    margin-left: 40px !important;
  }
  .aparEmailBox .aptHeaderText {
    text-align: start;
  }
  .userInfoInputBox {
    min-width: 750px;
    width: 55% !important;
    height: 60vh !important;
  }
}
