.termsMainSection {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}
.termsTextSection {
  width: 95%;
  display: flex;
  flex-direction: column;
}
.termsHeader {
  font-size: 30px !important;
  font-family: "PoppinsBold" !important;
  color: #fffc;
  margin-bottom: 18px !important;
  margin-top: 30px !important;
}
.termNText {
  font-size: 16px !important;
  font-family: "Satoshi" !important;
  color: #fffc;
}
.gapBox {
  margin: 5px 0;
}
.TermsPage .propertyDetailSection {
  position: relative;
  top: -1px;
}
/* Tabe view */
@media only screen and (min-width: 768px) {
  .termsTextSection {
    width: 90%;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .termsTextSection {
    width: 75%;
    padding: 50px 0;
  }
}
