.BlogSection {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}
.blogInSection {
  width: 100%;
  border: 1px solid #69696938;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
  position: relative;
  top: -80px;
  border-radius: 13px;
  background: rgb(37 37 37 / 58%);
  backdrop-filter: blur(5px);
  gap: 25px;
}
.blogHeader {
  font-size: 21px !important;
  font-family: "PoppinsBold" !important;
}
.blogInSection p {
  color: #fffc;
}
.blogSubText {
  width: 100%;
  text-align: center;
}
.blogCard {
  width: 100%;
  border: 1px solid rgb(90, 90, 90);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 15px;
  gap: 10px;
}
.blogCard img {
  width: 100%;
}
.BlogCbtnBox {
  width: 100%;
  display: flex;
  justify-content: end;
}
.BlogCbtnBox .appBtn {
  width: 144px;
  min-height: 40px;
}

/* Tabe view */
@media only screen and (min-width: 768px) {
  .blogInSection {
    width: 95%;
  }
  .blogCard {
    width: 48%;
  }
}
/* Desktop view */
@media only screen and (min-width: 1000px) {
  .blogInSection {
    width: 80%;
  }
  .blogCard {
    width: 42%;
  }
}
