.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.footerTextBox {
  width: 100%;
  position: relative;
  background: #000;
  padding: 30px 0;
}
.footerTextBox .fobg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  opacity: 0.4;
  z-index: 1;
}
.footerBottmBox {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
}
.footerBottmBox p {
  font-family: "Satoshi" !important;
  font-size: 15px !important;
  color: #ffffffb8;
}
.footerRowOuterBox {
  width: 100%;
  display: flex;
  justify-content: center;
}
.footerRowList {
  width: 85%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  z-index: 2;
  flex-wrap: wrap;
}
.footerNText {
  color: #ffeded;
  font-size: 15px !important;
  font-family: "Satoshi" !important;
  cursor: pointer;
  transition: all 0.3s;
}
.footerNText:hover {
  color: #ffd29f;
}
.footerInnerText {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 50px;
  z-index: 2;
  gap: 50px;
}
.social {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
.social img {
  cursor: pointer;
}
.footerRowItem {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.conctText {
  font-size: 20px !important;
  font-family: "Satoshi" !important;
  color: #fff;
}
.footerRowHText {
  font-size: 19px !important;
  font-family: "Poppins" !important;
  font-weight: 700 !important;
  color: #fff;
}
.footerClogo {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footerClogo .footerClogoImg {
  width: 100px;
}
/* Tabe view */
@media only screen and (min-width: 768px) {
  .footer {
  }
  .footerInnerText {
    width: 95%;
    flex-direction: row;
    gap: unset;
  }
  .footerRowHText {
    font-size: 15px !important;
  }
  .conctText {
    font-size: 17px !important;
  }
  .footerTextBox {
    height: 400px;
    padding: 80px 0;
  }
  .footerRowList {
    width: 48%;

    flex-wrap: unset;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .footerInnerText {
    width: 70%;
  }
  .footerRowList {
    width: 28%;
  }
  .footerRowHText {
    font-size: 19px !important;
  }
  .conctText {
    font-size: 20px !important;
  }
}
