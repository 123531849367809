:root {
  --primaryColor: #fdaf17;
}
.contactSubHeader {
  font-family: "Satoshi" !important;
  color: #ffffffd8;
  font-size: 18px !important;
  width: 100%;
  text-align: center;
}
.getTouchBox {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
}
.getTouchInnderBox {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}
.getTTextBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.getTMapBox {
  width: 100%;
  height: 326px;
  border-radius: 16px;
}

.getTMapBox > div {
  height: 100% !important;
  border-radius: 16px;
  overflow: hidden;
}
.getTHeader {
  font-family: "PoppinsBold" !important;
  font-size: 30px !important;
  color: #fff;
}
.getTSubHeader {
  font-family: "Satoshi" !important;
  color: #ffffffd5;
  font-size: 16px !important;
}
.getTItemBox {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.getTItem {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.getTItem span {
  cursor: pointer;
}
.GTItemIconBox {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: rgb(41, 41, 41);
}
.getTItem P {
  font-family: "PoppinsBold" !important;
  font-size: 18px !important;
  color: #fff;
}
.getTItem span {
  font-family: "Satoshi" !important;
  font-size: 14px !important;
  color: #ffffffc5;
}
.messageBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
}
.messageHeaderBox {
  width: 100%;
  height: 400px;
  background: var(--primaryColor);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 47px;
}
.messageHeaderBox p {
  font-family: "PoppinsBold" !important;
  color: #000;
  font-size: 30px !important;
}
.messageHeaderBox span {
  width: 95%;
  font-family: "Satoshi" !important;
  color: #000000d8;
  font-size: 15px !important;
  text-align: center;
}
.messageFooterBox {
  width: 100%;
  height: 952px;
  position: relative;
  display: flex;
  justify-content: end;
  padding-bottom: 100px;
}
.messageInputBox {
  width: 95%;
  background: #181717;
  position: absolute;
  top: 248px;
  padding: 20px;
  border-radius: 10px;
}
.inputTowBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  margin-bottom: 35px;
}
.inputTowBox .inputBox {
  width: 100%;
}
.inputBox {
  height: 50px;
  border-radius: 7px;
  border: 1px solid #3d3d3d;
  background: #00000000;
  position: relative;
}
.inputBox p {
  position: absolute;
  top: -13px;
  left: 20px;
  font-family: "Satoshi" !important;
  font-size: 14px !important;
  color: #ffffffdc;
  background: #181717;
  padding: 0 6px;
}
.inputBox input {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 7px;
  background: #00000000;
  font-family: "Satoshi" !important;
  color: #ffffffdc;
  font-weight: 400 !important;
  font-size: 14px;
  padding-left: 20px;
}
.inputBox .dropBox {
  z-index: 2;
}
.inputBox .dropItem {
  color: #fff;
  font-family: "Satoshi" !important;
  font-size: 14px !important;
}
.inputBox input:focus {
  outline: none;
}
.messageInput {
  width: 100%;
  height: 120px;
}
.messageInput textarea {
  width: 100%;
  height: 100%;
  border: none;
  padding: 20px;
  background: #00000000;
  font-family: "Satoshi" !important;
  font-weight: 400 !important;
  color: #ffffffdc;
  font-size: 17px;
}
.messageInput textarea:focus {
  outline: none;
}
.subjectInput {
  width: 100%;
  margin-bottom: 30px;
}
.countryDropImg {
  position: absolute;
  top: 17px;
  left: 10px;
  width: 18px;
  height: 14px;
}
.fromBtnBox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10%;
}
.mideaText {
  width: 100%;
  display: flex;
  justify-content: end;
  flex-direction: column;
}
.mideaText p {
  width: 100%;
  text-align: center;
  font-size: 35px !important;
  font-family: "PoppinsBold" !important;
  color: #fff;
}
.mideaBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 23px;
  gap: 20px;
}
.mideaBox > div {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #252323;
  border-radius: 12px;
  cursor: pointer;
}

/* Tabe view */
@media only screen and (min-width: 768px) {
  .getTouchInnderBox {
    width: 90%;
    flex-direction: row;
    gap: 20px;
  }
  .messageHeaderBox span {
    width: 90%;
  }
  .messageInputBox {
    width: 90%;
    padding: 40px;
  }
  .getTTextBox {
    width: 50%;
  }
  .getTMapBox {
    width: 50%;
  }
  .getTouchBox {
    height: 500px;
  }
  .messageHeaderBox {
    padding-top: 100px;
  }
  .inputTowBox {
    flex-direction: row;
  }
  .inputTowBox .inputBox {
    width: 50%;
  }
  .messageFooterBox {
    height: 749px;
  }
  .fromBtnBox {
    margin-top: 3%;
  }
  .inputBox input {
    font-size: 17px;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .getTouchInnderBox {
    width: 75%;
  }
  .messageHeaderBox span {
    width: 53%;
  }
  .messageInputBox {
    width: 80%;
  }
}
