:root {
  --primaryColor: #fdaf17;
}
.propertyPage {
  position: relative;
}
.propertyHomeSection {
  width: 100%;
  height: 250px;
  position: relative;
}
.propertyHomeSection .HomeBG {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  z-index: -2;
}
.propertyHomeSection .propertyheaderText {
  width: 100%;
  text-align: center;
  font-family: "PoppinsBold" !important;
  font-size: 20px !important;
  color: #fffae6;
  margin-top: 44px;
}
.propertyDetailSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  position: relative;
}
.propertyTitleBox {
  width: 95%;
  margin-bottom: 37px;
}
.proTilInnerBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 15px;
  gap: 20px;
  position: relative;
}
.propertyTitle {
  font-family: "Satoshi" !important;
  font-size: 25px !important;
  font-weight: 700 !important;
  color: #fff;
}
.propertyPriceText {
  font-family: "Satoshi" !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  color: #fff;
}
.propertyPriceText span {
  color: #fffae694;
}
.propertyBg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  z-index: -1;
}
.propLocBox {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-wrap: wrap;
}
.pIconBtnBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
.propContactBtn {
  width: 185px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 27px;
  background: #fdaf17;
  transition: background 0.5s;
  cursor: pointer;
}
.propContactBtn:hover {
  background: #fdb017ce;
}
.propContactBtn p {
  font-size: 16px;
  font-family: "Satoshi" !important;
  color: #fff;
}
.locText {
  min-width: 130px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.locText p {
  color: #7d7f88;
}
.start_Box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.start_Box p {
  font-size: 16px !important;
  font-family: "Satoshi" !important;
  color: #ffffff;
}
.start_Box span {
  font-size: 16px !important;
  font-family: "Satoshi" !important;
  color: #7d7f88;
}
.perpelBtn {
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #000000;
  border: 1px solid #f1c121;
  border-radius: 10px;
  padding: 0 8px;
  cursor: pointer;
}
.perpelBtn p {
  color: #fff;
  font-size: 14px !important;
}
.Img_Slider {
  width: 95%;
  height: 400px;
  margin-bottom: 20px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.Img_Slider > div {
  width: 100% !important;
  height: 100% !important;
}
.Img_Slider > div > div {
  width: 100% !important;
  height: 100% !important;
}
.properyImgBox {
  width: 95%;
  height: 255px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  position: relative;
  margin-bottom: 50px;
}
.imgBox1 {
  width: 30%;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.imgBox1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.imgBox1 video {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}
.imgBox2 {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
}
.img2Inn {
  width: 100%;
  height: 50%;
  border-radius: 10px;
  overflow: hidden;
}
.img2Inn img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.showAllBtn {
  position: absolute;
  bottom: 11px;
  right: 11px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
  height: 40px;
  background-color: #000000;
  border: 1px solid var(--primaryColor);
  border-radius: 10px;
  padding: 0 10px;
  cursor: pointer;
}
.showAllBtn p {
  color: #fff;
  font-family: "Satoshi" !important;
}

.propertyInfoBox {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 30px;
}
.propertyInfoInBox {
  padding: 15px;
  border-radius: 15px;
  box-shadow: rgba(255, 255, 255, 0.2) 0px 7px 29px 0px;
}
.overViewBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
}
.facilitiesBox {
  width: 100%;
  /* height: 300px; */
}
.overTitelBOx {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.overViewTitle {
  font-family: "Poppins" !important;
  color: #fff;
  font-size: 22px !important;
}
.overSubHeader {
  font-family: "Satoshi" !important;
  color: #fff;
  font-size: 16px !important;
}
.locationBox {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.overAvatarInfoBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.avatarBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.overAvatarInfoBox .avatarNamBox p {
  font-family: "Poppins" !important;
  font-size: 16px !important;
  color: #fff;
}
.overAvatarInfoBox .avatarNamBox span {
  font-family: "Satoshi" !important;
  font-size: 16px !important;
  color: #7d7f88;
}
.mesCallBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}
.mesCallBox > div {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #7171712e;
  cursor: pointer;
  transition: all 0.5s;
}
.mesCallBox > div:hover {
  background: #717171ad;
}
.facilitiesItemBox {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}
.facilitesItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.facilitesItem p {
  color: #fff;
  font-size: 11px;
}
.facilitesItem img {
  width: 20px;
  height: 20px;
}
.facilOuterBox {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
  margin-top: 20px;
}
.aboutLocBox {
  width: 100%;
}
.testimonialsBox {
  width: 100%;
}
.aboutLocBox p {
  color: #fff;
  font-size: 16px !important;
}
.costBtn {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 27px;
  background: var(--primaryColor);
  gap: 16px;
  cursor: pointer;
  padding: 0 16px;
}
.costBtn span {
  font-family: "Satoshi" !important;
  color: #fff;
}
.testimonialsBox .avatarNamBox p {
  color: #fff;
}
.testimonialsBox .reviewText {
  font-family: "Satoshi" !important;
  color: #fff;
}
.testimonialsBox .reviewText span {
  color: #9c9c9c;
  cursor: pointer;
}
.reviewBox {
  margin: 20px 0;
}
.MapBox {
  width: 95%;
  height: 285px;
  padding: 15px;
  border-radius: 15px;
  box-shadow: rgba(255, 255, 255, 0.2) 0px 7px 29px 0px;
  margin-top: 10px;
  margin-bottom: 30px;
}
.MapBox > div {
  height: 100% !important;
  border-radius: 15px;
  overflow: hidden;
}
.rentSection {
  width: 95%;
  height: 100px;
  border-radius: 15px;
  box-shadow: rgba(255, 255, 255, 0.2) 0px 7px 29px 0px;
  margin-top: 10px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  position: relative;
}
.estimatPop {
  position: absolute;
  top: 111px;
  left: 0px;
  width: 250px;
  height: 0px;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 13px;
  gap: 10px;
  transition: all 0.5s;
  overflow: hidden;
  z-index: 9;
}
.estimatPopActive {
  height: 120px;
  padding: 10px 20px;
}
.estimatPop p {
  font-size: 14px;
  font-family: "Poppins" !important;
  color: #000000;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.185);
}
.estimatPop img {
  position: absolute;
  top: 5px;
  right: 4px;
  cursor: pointer;
}
.rentSection samp {
  font-size: 14px;
  color: #fff;
  text-decoration: underline;
  font-family: "Courier New", Courier, monospace;
}
.propertyDetailSection .apartmentBox {
  background-image: none;
}
.rentSection .appBtn {
  width: 100px;
}
.backDrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background: #7d7f88ad;
}
.backDrop > div {
  width: 95%;
  height: 400px;
}
.backDrop > div > div {
  width: 95%;
  height: 400px;
}
.contactBtnBox {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: start;
  gap: 20px;
}
.whatsappIcon {
  width: 40px;
  cursor: pointer;
}
.socioStayIcon {
  width: 40px;
  height: 40px;
}
.loadingText {
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: #fdaf17;
  background: #1f1f1f;
  padding: 100px 0;
  font-family: "PoppinsBold";
}
.backDrop .rentDelInputBox {
  width: 99%;
  height: auto;
  background: #181717;
  border-radius: 10px;
  padding: 30px 20px;
}
.bd2 > div > div {
  width: 100% !important;
  height: auto !important;
}
.rentPopHeader {
  text-align: center;
  font-size: 24px;
  color: #fff;
  font-weight: 600;
  font-family: "Poppins";
  margin-bottom: 30px;
}
.rentDelInputBox .inputTowBox {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.rentDelInputBox .inputTowBox .inputBox {
  width: 100%;
}
.fixedBannerBox {
  position: sticky;
  bottom: 0px;
  background: #1e1e1e;
  padding: 15px;
}
/* Tabe view */
@media only screen and (min-width: 768px) {
  .propertyDetailSection {
    padding-top: 60px;
  }
  .propertyTitleBox {
    width: 90%;
  }
  .properyImgBox {
    width: 90%;
    height: 45vh;
  }
  .Img_Slider {
    width: 90%;
  }
  .propertyInfoBox {
    width: 90%;
    flex-direction: row;
  }
  .MapBox {
    width: 90%;
  }
  .rentSection {
    width: 90%;
  }
  .facilitesItem p {
    color: #fff;
    font-size: 11px;
  }
  .aboutLocBox {
    width: 51%;
  }
  .testimonialsBox {
    width: 49%;
  }
  .propLocBox {
    flex-wrap: unset;
  }
  .overViewBox {
    width: 60%;
  }
  .facilitiesBox {
    width: 40%;
  }
  .imgBox1 img {
    width: 100%;
  }
  .img2Inn img {
    width: 100%;
  }
  .rentSection .appBtn {
    width: 207px;
  }
  .propertyHomeSection .propertyheaderText {
    font-size: 30px !important;
    margin-top: 90px;
  }
  .propertyHomeSection {
    height: 400px;
  }
  .propertyInfoBoxv2 {
    flex-direction: column;
  }
  .aboutLocBox {
    width: 100%;
  }
  .testimonialsBox {
    width: 100%;
  }
  .fixedBannerBox {
    display: none;
  }
  .propertyInfoInBox {
    padding: 30px;
  }
  .MapBox {
    padding: 20px;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .propertyDetailSection {
    padding-top: 100px;
  }
  .propertyTitleBox {
    width: 75%;
  }
  .properyImgBox {
    width: 75%;
    height: 70vh;
  }
  .Img_Slider {
    width: 75%;
    height: 500px;
  }
  .propertyInfoBox {
    width: 75%;
  }
  .MapBox {
    width: 75%;
  }
  .rentSection {
    width: 75%;
  }
  .proTilInnerBox {
    flex-direction: row;
    gap: unset;
  }
  .facilitesItem p {
    color: #fff;
    font-size: 16px;
  }
  .aboutLocBox {
    width: 60%;
  }
  .testimonialsBox {
    width: 40%;
  }
  .costBtn {
    width: fit-content;
  }
  .propertyInfoBoxv2 {
    flex-direction: unset;
  }
  .backDrop > div {
    width: 70%;
    height: 80vh;
  }
  .backDrop > div > div {
    width: 70%;
    height: 80vh;
  }
  .contactBtnBox {
    width: unset;
    flex-direction: row;
    justify-content: end;
  }
  .backDrop .rentDelInputBox {
    width: 50%;
  }
}
