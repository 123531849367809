/* nav bar */
.navBar {
  width: 100%;
  height: 70px;
  padding: 0 4%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.navRBox {
  display: none;
  width: 70%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.navRBox p {
  cursor: pointer;
  transition: 0.5s all;
}
.navRBox p:hover {
  color: #fcaf17;
}
.logInBox {
  /* width: 185px; */
  width: 55px;
  height: 40px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background: #01071552;
  cursor: pointer;
  border-radius: 5px;
}
.navBar .navCLogo {
  width: 67px;
  height: 48px;
}
.sideMenu .navItem p {
  font-size: 30px;
  font-family: "Satoshi";
  color: #fff;
  font-weight: 500 !important;
}
.navBar p {
  font-family: "Satoshi";
  color: #fff;
  font-weight: 500 !important;
}
.humMenu {
  display: block;
}
.sideMenu {
  transition: all 0.8s;
  z-index: 99999;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7%;
  overflow: hidden;
  background: #000000c4;
}

.subItemDeactivate .navItem {
  display: none;
}
.backIcon {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 40px;
}
/* Tabe view */
@media only screen and (min-width: 768px) {
  .navRBox {
    width: 74%;
    display: flex;
  }
  .navBar {
    padding: 0 6%;
    height: 100px;
  }
  .navBar .navCLogo {
    width: 93px;
    height: 62px;
  }
  .humMenu {
    display: none;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .navRBox {
    width: 57%;
  }
  .navBar {
    padding: 0 10%;
  }
}
