.popup {
  position: absolute;
  top: 94px;
  left: 0px;
  margin: auto;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.popup button {
  display: block;
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.popup button:hover {
  background-color: #0056b3;
}
.popup img {
  width: 40px;
}
.PopCross {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 20px !important;
}
